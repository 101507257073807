import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const DiseñoPublicitarioPage = () => {
    const data = useStaticQuery(graphql`
    query {
      publicitarioImage1: file(relativePath: { eq: "images/sections/diseno-grafico/publicitario/diseno-grafico-de-marca-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      publicitarioImage2: file(relativePath: { eq: "images/sections/diseno-grafico/publicitario/diseno-comercial-empresas-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      publicitarioImage3: file(relativePath: { eq: "images/sections/diseno-grafico/publicitario/diseno-publicitario-canales-digitales.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Diseño Publicitario"
                description="Agencia de Publicidad en Barcelona y Agencia de Diseño en Barcelona experta en diseño comercial y diseño publicitario."
            />

            <BannerPage
                title="Expertos en diseño publicitario"
                text="Diseño de materiales gráficos comerciales"
                color="pink"
            />

            <SectionTextImage
                title1="Diseño gráfico "
                title2="de marca"
                text={<Fragment>
                    ¿Necesitas crear una imagen de tu empresa, negocio o marca? En IBX somos especialistas en el diseño de todo tipo de <strong>identidades y papelerías corporativas</strong>:<br /><br />
                    <ul className="text-left">
                        <li>Logotipos y anagramas</li>
                        <li>Tarjetas, cartas, sobres, albaranes, hojas de ruta, rótulos, sellos, etc.</li>
                    </ul>
                </Fragment>}
                image={data.publicitarioImage1.childImageSharp.fluid}
                imageAlt="Diseño gráfico de marca - Agencia Diseño Publicitario"
                color="pink"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Diseño comercial "
                title2="para empresas"
                text={<Fragment>
                    En IBX diseñamos todo tipo de <strong>materiales gráficos publicitarios comerciales</strong> para promocionar tu marca, producto o servicio. <br /><br />
                    <ul className="text-left">
                        <li>Cartelería, vinilos, pancartas, roll ups, rótulos, pancartas, etc.</li>
                        <li>Flyers, dípticos y trípticos</li>
                        <li>Packaging de producto</li>
                        <li>Stands para ferias y eventos</li>
                        <li>Material promocional: Merchandising</li>
                    </ul>
                </Fragment>}
                image={data.publicitarioImage2.childImageSharp.fluid}
                imageAlt="Diseño comercial para empresas - Agencia Diseño Publicitario"
                color="pink"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Diseño publicitario "
                title2="para canales digitales"
                text={<Fragment>
                    En IBX diseñamos todo tipo de <strong>materiales gráficos publicitarios para Internet</strong>:<br /><br />
                    <ul class="text-left">
                        <li>Páginas web, blogs y comercios electrónicos</li>
                        <li>Firmas de correos, newsletter y mailings</li>
                        <li>Banners y/o sliders para webs o medios de comunicación</li>
                        <li>Creatividades para redes sociales</li>
                        <li>Infografías</li>
                    </ul>
                </Fragment>}
                image={data.publicitarioImage3.childImageSharp.fluid}
                imageAlt="Diseño Publicitario para canales digitales - Agencia Diseño Publicitario"
                color="pink"
                containerTextRight={false}
            />

            <CallToAction
                color="pink"
                title="¿Quieres que le demos forma a tus piezas publicitarias?"
                text={<Fragment>
                    ¡Somos tu <strong>agencia de diseño comercial y publicitario en Barcelona</strong>!
                </Fragment>}
                button="¿Le damos forma a tu imagen?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default DiseñoPublicitarioPage
